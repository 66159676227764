import React from "react";
import { Drawer } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Cart from "@components/shop/cart";
import { useShopify } from "@context/shopify-context";

export default function CartDrawer() {
  const { opened, closeCartDrawer } = useShopify();
  const mobile = useMediaQuery("(max-width: 48em)", true, {
    getInitialValueInEffect: false,
  });

  return (
    <Drawer
      opened={opened}
      onClose={closeCartDrawer}
      title="Shopping Cart"
      position={mobile ? "bottom" : "right"}
      size={mobile ? "100%" : "lg"}
    >
      <Cart />
    </Drawer>
  );
}
