import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/notifications/styles.css";
import "@assets/styles/fonts.css";
import "@assets/styles/wordpress-theme.css";
import "@assets/styles/util.css";

import React from "react";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ShopifyProvider } from "@context/shopify-context";
import { theme, resolver } from "@assets/theme";
import CartDrawer from "@components/shop/cart/drawer";

export const wrapRootElement = ({ element }) => {
  return <ShopifyProvider>{element}</ShopifyProvider>;
};

export const wrapPageElement = ({ element }) => {
  return (
    <MantineProvider
      theme={theme}
      cssVariablesResolver={resolver}
      classNamesPrefix="bigmode"
      withGlobalStyles
      withNormalizeCSS
    >
      {element}
      <CartDrawer />
      <Notifications />
    </MantineProvider>
  );
};
