import React, { useMemo } from "react";
import { Link } from "gatsby";
import { Button, Group, Text, Divider, Image, Badge } from "@mantine/core";
import Fallback from "@components/general/fallback";
import CartLineItem from "@components/shop/cart/line-item";
import BiggyFace from "@assets/images/biggy-secondary-cropped.svg";
import { useShopify } from "@context/shopify-context";
import { formatPrice } from "@util/format-price";
import * as classes from "./component.module.css";

const OOS_WARNING =
  "Your cart contains items that are unavailable to ship immediately. Your order will not be shipped until all your items are available.";

export default function Cart() {
  const {
    cart,
    initialized,
    loading,
    removeLineItem,
    updateLineItem,
    closeCartDrawer,
  } = useShopify();

  const containsOutOfStock = useMemo(
    () =>
      cart?.lines?.some(
        (item) =>
          item.merchandise.availableForSale &&
          item.merchandise.currentlyNotInStock
      ),
    [cart]
  );

  if (!initialized) return <Fallback />;

  const emptyCart = !cart.id || cart.lines.length === 0;

  const orderDiscount = cart.discountAllocations
    ? cart.discountAllocations.reduce((total, discount) => {
        if (!total[discount.discountedAmount.currencyCode])
          total[discount.discountedAmount.currencyCode] = 0;
        total[discount.discountedAmount.currencyCode] += Number(
          discount.discountedAmount.amount
        );
        return total;
      }, {})
    : null;

  return (
    <div className={classes.cart} {...{ "data-empty": emptyCart }}>
      {emptyCart ? (
        <>
          <Image
            src={BiggyFace}
            alt="Close up of Biggy the Bigmode Frog"
            className={classes.biggy}
          />
          <Text fz="xl">Your cart is empty.</Text>
          <Button
            component={Link}
            to="/shop"
            color="orange"
            onClick={closeCartDrawer}
          >
            Continue Shopping
          </Button>
        </>
      ) : (
        <>
          <div className={classes.products}>
            {cart.lines.map((lineItem, index) => (
              <CartLineItem
                key={lineItem.id}
                lineItem={lineItem}
                remove={() => removeLineItem(lineItem.id)}
                update={(quantity) => updateLineItem(lineItem.id, quantity)}
                close={closeCartDrawer}
              />
            ))}
          </div>

          {containsOutOfStock && (
            <Group wrap="nowrap" mt="md">
              <Badge miw="fit-content">Note</Badge>
              <Text size="md">{OOS_WARNING}</Text>
            </Group>
          )}

          <Divider my="md" />

          <div className={classes.orderTotals}>
            {cart.cost.subtotalAmount.amount !==
              cart.cost.totalAmount.amount && (
              <Group justify="space-between" wrap="nowrap" mb="md">
                <Text>Subtotal</Text>
                <Text>
                  {formatPrice(
                    cart.cost.subtotalAmount.currencyCode,
                    cart.cost.subtotalAmount.amount
                  )}{" "}
                  {cart.cost.subtotalAmount.currencyCode}
                </Text>
              </Group>
            )}
            {orderDiscount[cart.cost.subtotalAmount.currencyCode] && (
              <Group
                justify="space-between"
                wrap="nowrap"
                mt="calc(var(--mantine-spacing-md) * -1)"
                mb="md"
              >
                <Text>Order Discount</Text>
                <Text c="red">
                  {`-${formatPrice(
                    cart.cost.subtotalAmount.currencyCode,
                    orderDiscount[cart.cost.subtotalAmount.currencyCode]
                  )} ${cart.cost.subtotalAmount.currencyCode}`}
                </Text>
              </Group>
            )}
            <Group justify="space-between" wrap="nowrap">
              <Text fw="bold" fz="xl">
                Total
              </Text>
              <Text fw="bold" fz="xl">
                {formatPrice(
                  cart.cost.totalAmount.currencyCode,
                  cart.cost.totalAmount.amount
                )}{" "}
                {cart.cost.totalAmount.currencyCode}
              </Text>
            </Group>
            <Text c="dimmed" size="md">
              Shipping and taxes will be calculated at checkout.
            </Text>
          </div>

          <div className={classes.cartActions}>
            <Button
              component="a"
              target="_self"
              href={cart.checkoutUrl}
              fullWidth
              disabled={loading}
            >
              Check Out
            </Button>

            <Button
              component={Link}
              to="/shop"
              onClick={closeCartDrawer}
              fullWidth
              variant="outline"
            >
              Continue Shopping
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
