import React from "react";
import {
  ActionIcon,
  Anchor,
  AppShell,
  Badge,
  Button,
  Checkbox,
  Container,
  Divider,
  Drawer,
  List,
  LoadingOverlay,
  Modal,
  NavLink,
  Notification,
  NumberInput,
  RadioGroup,
  Select,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { Notifications } from "@mantine/notifications";
import {
  FiChevronLeft as PreviousIcon,
  FiChevronRight as NextIcon,
} from "react-icons/fi";

// Style Definitions
import * as actionIconClasses from "@assets/styles/components/action-icon.module.css";
import * as anchorClasses from "@assets/styles/components/anchor.module.css";
import * as appShellClasses from "@assets/styles/components/app-shell.module.css";
import * as badgeClasses from "@assets/styles/components/badge.module.css";
import * as buttonClasses from "@assets/styles/components/button.module.css";
import * as carouselClasses from "@assets/styles/components/carousel.module.css";
import * as checkboxClasses from "@assets/styles/components/checkbox.module.css";
import * as containerClasses from "@assets/styles/components/container.module.css";
import * as drawerClasses from "@assets/styles/components/drawer.module.css";
import * as dividerClasses from "@assets/styles/components/divider.module.css";
import * as inputClasses from "@assets/styles/components/input.module.css";
import * as listClasses from "@assets/styles/components/list.module.css";
import * as modalClasses from "@assets/styles/components/modal.module.css";
import * as notificationClasses from "@assets/styles/components/notification.module.css";
import * as notificationsClasses from "@assets/styles/components/notifications.module.css";
import * as selectClasses from "@assets/styles/components/select.module.css";
import * as textClasses from "@assets/styles/components/text.module.css";
import * as textareaClasses from "@assets/styles/components/textarea.module.css";
import * as themeIconClasses from "@assets/styles/components/theme-icon.module.css";
import * as titleClasses from "@assets/styles/components/title.module.css";
import * as typographyClasses from "@assets/styles/components/typography.module.css";

export const components = {
  ActionIcon: ActionIcon.extend({
    defaultProps: { radius: "sm" },
    classNames: { root: actionIconClasses.root },
  }),
  Anchor: Anchor.extend({ classNames: { root: anchorClasses.root } }),
  AppShell: AppShell.extend({
    defaultProps: { layout: "alt", withBorder: false },
    classNames: { root: appShellClasses.root, main: appShellClasses.main },
  }),
  Badge: Badge.extend({
    defaultProps: { size: "md", variant: "filled" },
    classNames: { root: badgeClasses.root },
  }),
  Button: Button.extend({
    defaultProps: {
      size: "lg",
      variant: "filled",
    },
    classNames: { root: buttonClasses.root },
  }),
  Carousel: Carousel.extend({
    defaultProps: {
      previousControlProps: { "aria-label": "Show Previous" },
      nextControlProps: { "aria-label": "Show Next" },
      previousControlIcon: <PreviousIcon />,
      nextControlIcon: <NextIcon />,
      speed: 85,
      align: "start",
      loop: true,
      slideGap: "md",
      withIndicators: true,
    },
    classNames: {
      root: carouselClasses.root,
      container: carouselClasses.container,
      slide: carouselClasses.slide,
      controls: carouselClasses.controls,
      control: carouselClasses.control,
      indicators: carouselClasses.indicators,
    },
  }),
  CheckboxGroup: Checkbox.Group.extend({
    defaultProps: {
      size: "lg",
      inputWrapperOrder: ["label", "description", "error", "input"],
      classNames: { label: inputClasses.label },
    },
  }),
  Checkbox: Checkbox.extend({
    defaultProps: { size: "lg" },
    classNames: { input: checkboxClasses.input, label: inputClasses.label },
  }),
  Container: Container.extend({
    classNames: {
      root: containerClasses.root,
    },
  }),
  Drawer: Drawer.extend({
    defaultProps: {
      closeButtonProps: { "aria-label": "Close" },
      mod: { dark: true },
      overlayProps: {
        blur: 3,
        style: { "--overlay-bg": "rgba(0, 0, 0, .75)" },
      },
    },
    classNames: {
      content: drawerClasses.content,
      header: drawerClasses.header,
      title: drawerClasses.title,
      close: drawerClasses.close,
      body: drawerClasses.body,
    },
  }),
  Divider: Divider.extend({
    classNames: {
      root: dividerClasses.root,
      label: dividerClasses.label,
    },
  }),
  List: List.extend({
    defaultProps: { size: "lg", withPadding: true, spacing: "xxs" },
    classNames: { root: listClasses.root },
  }),
  LoadingOverlay: LoadingOverlay.extend({
    defaultProps: {
      loaderProps: {
        color: "var(--accent-color, var(--mantine-primary-color-filled))",
      },
      overlayProps: {
        blur: 3,
        color: "var(--mantine-color-body)",
        backgroundOpacity: 0.75,
      },
    },
  }),
  Modal: Modal.extend({
    defaultProps: {
      centered: true,
      withCloseButton: false,
      radius: "lg",
      mod: { dark: true },
      overlayProps: {
        blur: 3,
        style: { "--overlay-bg": "rgba(0, 0, 0, .75)" },
      },
    },
    classNames: {
      root: modalClasses.root,
      content: modalClasses.content,
      close: modalClasses.close,
    },
  }),
  NavLink: NavLink.extend({
    defaultProps: { noWrap: true },
  }),
  Notification: Notification.extend({
    defaultProps: {
      color: "orange",
      withCloseButton: true,
    },
    classNames: {
      root: notificationClasses.root,
      title: notificationClasses.title,
      body: notificationClasses.body,
      description: notificationClasses.description,
    },
  }),
  Notifications: Notifications.extend({
    defaultProps: {
      autoClose: 10000,
      position: "top-center",
      limit: 1,
      zIndex: 99,
      containerWidth: 860,
      mod: { timeout: true },
    },
    classNames: {
      root: notificationsClasses.root,
    },
  }),
  NumberInput: NumberInput.extend({
    defaultProps: {
      size: "lg",
      hideControls: true,
    },
    classNames: {
      wrapper: inputClasses.wrapper,
      input: inputClasses.input,
      label: inputClasses.label,
    },
  }),
  RadioGroup: RadioGroup.extend({
    defaultProps: { size: "lg" },
  }),
  Select: Select.extend({
    defaultProps: { size: "lg", allowDeselect: false },
    classNames: {
      input: selectClasses.input,
      dropdown: selectClasses.dropdown,
    },
  }),
  Text: Text.extend({
    defaultProps: { size: "lg" },
    classNames: { root: textClasses.root },
  }),
  Textarea: Textarea.extend({
    defaultProps: { size: "lg", autosize: true },
    classNames: {
      input: textareaClasses.input,
      description: textareaClasses.description,
    },
  }),
  TextInput: TextInput.extend({
    defaultProps: {
      size: "lg",
    },
    classNames: {
      wrapper: inputClasses.wrapper,
      input: inputClasses.input,
      label: inputClasses.label,
    },
  }),
  ThemeIcon: ThemeIcon.extend({
    classNames: { root: themeIconClasses.root },
  }),
  Title: Title.extend({
    classNames: { root: titleClasses.root },
  }),
  TypographyStylesProvider: TypographyStylesProvider.extend({
    classNames: { root: typographyClasses.root },
  }),
};
