const hasNextPage = (paginatedData) => {
  return (
    paginatedData &&
    paginatedData.length &&
    paginatedData[paginatedData.length - 1].pageInfo.hasNextPage
  );
};

const fetchNextPage = (client, nodeOrNodes, query, path, options) => {
  let node;
  if (Array.isArray(nodeOrNodes)) {
    node = nodeOrNodes[nodeOrNodes.length - 1];
  } else {
    node = nodeOrNodes;
  }

  const cursor = node.edges[node.edges.length - 1].cursor;

  return client
    .request(query, { variables: { ...options, cursor } })
    .then(defaultResolver(path));
};

export const fetchAllPages = (client, paginatedData, query, path, options) => {
  if (hasNextPage(paginatedData)) {
    return fetchNextPage(client, paginatedData, query, path, options).then(
      (response) => {
        const pages = paginatedData.concat(response);
        return fetchAllPages(client, pages, query, path, options);
      }
    );
  }

  return Promise.resolve(
    paginatedData.reduce(
      (nodes, page) => nodes.concat(page.edges.map((edge) => edge.node)),
      []
    )
  );
};

export const defaultResolver = (path) => {
  const keys = path.split(".");

  return function ({ data, errors }) {
    return new Promise((resolve, reject) => {
      try {
        const result = keys.reduce((ref, key) => {
          return ref[key];
        }, data);

        resolve(result);
      } catch (_) {
        if (errors) {
          reject(errors);
        } else {
          reject([{ message: "an unknown error has occurred." }]);
        }
      }
    });
  };
};
