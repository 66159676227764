exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-wp-game-slug-js": () => import("./../../../src/pages/games/{WpGame.slug}.js" /* webpackChunkName: "component---src-pages-games-wp-game-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-secret-zone-js": () => import("./../../../src/pages/secret-zone.js" /* webpackChunkName: "component---src-pages-secret-zone-js" */),
  "component---src-pages-shop-cart-js": () => import("./../../../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-faq-js": () => import("./../../../src/pages/shop/faq.js" /* webpackChunkName: "component---src-pages-shop-faq-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shop-shopify-product-handle-js": () => import("./../../../src/pages/shop/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-shop-shopify-product-handle-js" */),
  "component---src-pages-wp-page-slug-js": () => import("./../../../src/pages/{WpPage.slug}.js" /* webpackChunkName: "component---src-pages-wp-page-slug-js" */)
}

