import React from "react";
import { Stack, Loader } from "@mantine/core";

export default function Fallback({ color = null, ...props }) {
  return (
    <Stack flex="1" align="center" justify="center" {...props}>
      <Loader color={color} />
    </Stack>
  );
}
