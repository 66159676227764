import React, { useRef } from "react";
import { Group, NumberInput, ActionIcon } from "@mantine/core";
import { FiPlus as PlusIcon, FiMinus as MinusIcon } from "react-icons/fi";
import classnames from "classnames";
import * as classes from "./quantity-input.module.css";

function QuantityInputContainer({
  handlers,
  disabled,
  preventIncrement,
  preventDecrement,
  size,
  inputRef,
  children,
}) {
  return (
    <Group wrap="nowrap" className={classes.group} mod={{ disabled }}>
      <ActionIcon
        onMouseDown={(event) => event.preventDefault()}
        onPointerDown={(event) => {
          if (!disabled && !preventDecrement) handlers.current?.decrement();
        }}
        onMouseUp={(e) => inputRef.current.blur()}
        onTouchEnd={(e) => inputRef.current.blur()}
        size={size}
        variant="subtle"
        disabled={disabled || preventDecrement}
        className={classnames("static", classes.button)}
        aria-label="Decrement Quantity"
      >
        <MinusIcon />
      </ActionIcon>
      {children}
      <ActionIcon
        onMouseDown={(event) => event.preventDefault()}
        onPointerDown={(event) => {
          if (!disabled && !preventIncrement) handlers.current?.increment();
        }}
        onMouseUp={(e) => inputRef.current.blur()}
        onTouchEnd={(e) => inputRef.current.blur()}
        size={size}
        variant="subtle"
        disabled={disabled || preventIncrement}
        className={classnames("static", classes.button)}
        aria-label="Increment Quantity"
      >
        <PlusIcon />
      </ActionIcon>
    </Group>
  );
}

export default function QuantityInput({
  disabled = false,
  quantity = 0,
  max = 99,
  min = 1,
  size = "lg",
  ...props
}) {
  const input = useRef();
  const handlers = useRef();
  return (
    <NumberInput
      {...props}
      ref={input}
      disabled={disabled}
      handlersRef={handlers}
      classNames={{ input: classes.input }}
      value={quantity}
      startValue={quantity}
      max={max}
      min={min}
      size={size}
      inputContainer={(children) => (
        <QuantityInputContainer
          handlers={handlers}
          disabled={disabled}
          preventIncrement={max ? quantity === max : false}
          preventDecrement={quantity === min}
          size={size}
          inputRef={input}
        >
          {children}
        </QuantityInputContainer>
      )}
    />
  );
}
