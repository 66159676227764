import { print } from "graphql/language";
import { fetchAllPages, defaultResolver } from "@api/shopify";
import CART_CREATE_MUTATION from "@api/shopify/cart/mutations/cartCreate.graphql";
import CART_QUERY from "@api/shopify/cart/queries/cart.graphql";
import CART_LINES_QUERY from "@api/shopify/cart/queries/cartLines.graphql";
import CART_LINES_ADD_MUTATION from "@api/shopify/cart/mutations/cartLinesAdd.graphql";
import CART_LINES_REMOVE_MUTATION from "@api/shopify/cart/mutations/cartLinesRemove.graphql";
import CART_LINES_UPDATE_MUTATION from "@api/shopify/cart/mutations/cartLinesUpdate.graphql";

const fetchChildResourcesForCart = (cart, client) => {
  if (cart === null) return Promise.reject("No cart found.");

  return fetchAllPages(
    client,
    [cart.lines],
    print(CART_LINES_QUERY),
    "cart.lines",
    {
      id: cart.id,
    }
  ).then((lines) => {
    cart.lines = lines;

    return cart;
  });
};

const paginateCartConnectionsAndResolve = (client) => {
  return function (cart) {
    return fetchChildResourcesForCart(cart, client).then(() => {
      return cart;
    });
  };
};

export const createCart = (client, input = {}) => {
  return client
    .request(print(CART_CREATE_MUTATION), {
      variables: {
        input,
      },
    })
    .then(defaultResolver("cartCreate.cart"))
    .then(paginateCartConnectionsAndResolve(client));
};

export const fetchCart = (client, cartId) => {
  return client
    .request(print(CART_QUERY), {
      variables: {
        id: cartId,
      },
    })
    .then(defaultResolver("cart"))
    .then(paginateCartConnectionsAndResolve(client));
};

export const addCartLines = (client, cartId, lines = []) => {
  return client
    .request(print(CART_LINES_ADD_MUTATION), {
      variables: {
        cartId,
        lines,
      },
    })
    .then(defaultResolver("cartLinesAdd.cart"))
    .then(paginateCartConnectionsAndResolve(client));
};

export const removeCartLines = (client, cartId, lineIds = []) => {
  return client
    .request(print(CART_LINES_REMOVE_MUTATION), {
      variables: {
        cartId,
        lineIds,
      },
    })
    .then(defaultResolver("cartLinesRemove.cart"))
    .then(paginateCartConnectionsAndResolve(client));
};

export const updateCartLines = (client, cartId, lines = []) => {
  return client
    .request(print(CART_LINES_UPDATE_MUTATION), {
      variables: {
        cartId,
        lines,
      },
    })
    .then(defaultResolver("cartLinesUpdate.cart"))
    .then(paginateCartConnectionsAndResolve(client));
};
