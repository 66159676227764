import { print } from "graphql/language";
import { fetchAllPages, defaultResolver } from "@api/shopify";
import PRODUCT_VARIANTS_QUERY from "@api/shopify/product/queries/variants.graphql";
import PRODUCT_QUERY from "@api/shopify/product/queries/product.graphql";
import PRODUCT_PREVIEW_QUERY from "@api/shopify/product/queries/preview.graphql";

const fetchChildResourcesForProducts = (productOrProduct, client) => {
  const products = [].concat(productOrProduct);

  return Promise.all(
    products.reduce((promiseAcc, product) => {
      // If the graphql query doesn't find a match, skip fetching variants.
      if (product === null) {
        return promiseAcc;
      }
      // Fetch additional variants, if needed
      promiseAcc.push(
        fetchAllPages(
          client,
          [product.variants],
          print(PRODUCT_VARIANTS_QUERY),
          "product.variants",
          {
            id: product.id,
          }
        ).then((variants) => {
          product.variants = variants;

          return product;
        })
      );

      return promiseAcc;
    }, [])
  );
};

const paginateProductConnectionsAndResolve = (client) => {
  return function (products) {
    return fetchChildResourcesForProducts(products, client).then(() => {
      return products;
    });
  };
};

export const fetchProduct = (client, productId) => {
  return client
    .request(print(PRODUCT_QUERY), {
      variables: {
        id: productId,
      },
    })
    .then(defaultResolver("product"))
    .then(paginateProductConnectionsAndResolve(client));
};

export const fetchProductPreview = (client, productId) => {
  return client
    .request(print(PRODUCT_PREVIEW_QUERY), {
      variables: {
        id: productId,
      },
    })
    .then(defaultResolver("product"));
};
