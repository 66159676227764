import React, { createContext, useContext, useState, useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import { createStorefrontApiClient } from "@shopify/storefront-api-client";
import { fetchProduct, fetchProductPreview } from "@api/shopify/product";
import {
  createCart,
  fetchCart,
  addCartLines,
  removeCartLines,
  updateCartLines,
} from "@api/shopify/cart";

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION,
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

const defaultValues = {
  client,
  initialized: false,
  loading: false,
  cart: {},
  opened: false,
  openCartDrawer: () => {},
  closeCartDrawer: () => {},
  addLineItem: async () => {},
  removeLineItem: async () => {},
  updateLineItem: async () => {},
  getProduct: async () => {},
  getProductPreview: async () => {},
};

export const ShopifyContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify-cart-id`;

export const ShopifyProvider = ({ children }) => {
  const [cart, setCart] = useState(defaultValues.cart);
  const [initialized, setInitialized] = useState(defaultValues.initialized);
  const [loading, setLoading] = useState(defaultValues.loading);
  const [opened, { open: openCartDrawer, close: closeCartDrawer }] =
    useDisclosure(defaultValues.opened);

  const saveCart = (cart) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, cart.id);
    }

    setCart(cart);
  };

  // Load stored cart if found in browser
  useEffect(() => {
    const existingCartId = isBrowser
      ? localStorage.getItem(localStorageKey)
      : null;

    if (existingCartId && existingCartId !== `null`) {
      fetchCart(client, existingCartId)
        .then(saveCart)
        .catch((e) => {
          console.log("Cart load Error:", e);
          localStorage.removeItem(localStorageKey);
        })
        .finally(() => {
          setInitialized(true);
        });
    } else {
      setInitialized(true);
    }
  }, []);

  const createNewCart = (lines = []) => {
    return createCart(client, { lines })
      .then((updatedCart) => {
        saveCart(updatedCart);
        return updatedCart;
      })
      .catch((e) => {
        console.error(e.message);
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addLineItem = (merchandiseId, quantity) => {
    setLoading(true);

    const lineItemsToAdd = [
      {
        merchandiseId,
        quantity: parseInt(quantity, 10),
      },
    ];

    if (!cart?.id) {
      return createNewCart(lineItemsToAdd);
    }

    return addCartLines(client, cart.id, lineItemsToAdd)
      .then((updatedCart) => {
        setCart(updatedCart);
        return updatedCart;
      })
      .catch((e) => {
        console.error(e.message);
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeLineItem = (merchandiseId) => {
    setLoading(true);

    return removeCartLines(client, cart.id, [merchandiseId])
      .then((updatedCart) => {
        setCart(updatedCart);
        return updatedCart;
      })
      .catch((e) => {
        console.error(e.message);
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateLineItem = (lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return updateCartLines(client, cart.id, lineItemsToUpdate)
      .then((updatedCart) => {
        setCart(updatedCart);
        return updatedCart;
      })
      .catch((e) => {
        console.error(e.message);
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProduct = (productId) => {
    return fetchProduct(client, productId);
  };

  const getProductPreview = (productId) => {
    return fetchProductPreview(client, productId);
  };

  return (
    <ShopifyContext.Provider
      value={{
        ...defaultValues,
        initialized,
        loading,
        cart,
        addLineItem,
        removeLineItem,
        updateLineItem,
        opened,
        openCartDrawer,
        closeCartDrawer,
        getProduct,
        getProductPreview,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = () => useContext(ShopifyContext);
