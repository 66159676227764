import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import {
  Group,
  Stack,
  Text,
  Image,
  Anchor,
  Badge,
  Loader,
  ActionIcon,
  LoadingOverlay,
} from "@mantine/core";
import { useDebouncedCallback } from "@mantine/hooks";
import {
  FiTrash2 as RemoveIcon,
  FiSlash as ErrorIcon,
  FiCheck as SuccessIcon,
  FiAlertTriangle as WarningIcon,
} from "react-icons/fi";
import QuantityInput from "@components/inputs/quantity-input";
import { formatPrice } from "@util/format-price";
import placeholder from "@assets/images/shop/placeholder.svg";
import * as classes from "./line-item.module.css";

export default function CartLineItem({
  lineItem,
  remove = () => {},
  update = () => {},
  close = () => {},
}) {
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [holdUpdate, setHoldUpdate] = useState(false);
  const [status, setStatus] = useState(null);
  const [quantity, setQuantity] = useState(lineItem.quantity);
  const productPage = `/shop/${lineItem.merchandise.product.handle}`;

  const handleUpdateLineItem = useDebouncedCallback((value) => {
    if (!holdUpdate && dirty) {
      setLoading(true);
      update(value)
        .then((updatedCart) => {
          if (!updatedCart) {
            throw Error("Something went wrong");
          }

          if (updatedCart.message) {
            throw Error(updatedCart?.message);
          }

          const updatedLineItem = updatedCart.lines.find(
            (newLineItem) => newLineItem.id === lineItem.id
          );

          if (
            !updatedLineItem ||
            updatedLineItem.quantity === lineItem.quantity
          ) {
            throw Error("Something went wrong");
          }

          if (updatedLineItem.quantity !== value) {
            setStatus("WARNING");
          } else {
            setStatus("SUCCESS");
          }

          setQuantity(updatedLineItem.quantity);
        })
        .catch((e) => {
          console.log(e.message);
          setQuantity(lineItem.quantity);
          setStatus("ERROR");
        })
        .finally(() => {
          setDirty(false);
          setTimeout(() => {
            setLoading(false);
            setStatus(null);
          }, 1000);
        });
    }
  }, 1000);

  const handleRemoveLineItem = () => {
    setLoading(true);
    remove()
      .then((updatedCart) => {
        if (!updatedCart) {
          throw Error("Something went wrong");
        }

        if (updatedCart.message) {
          throw Error(updatedCart?.message);
        }
        setStatus("SUCCESS");
      })
      .catch(() => {
        setStatus("ERROR");
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setStatus(null);
        }, 1000);
      });
  };

  useEffect(() => {
    if (!holdUpdate && quantity !== lineItem.quantity) {
      handleUpdateLineItem(quantity);
    }
  }, [quantity, lineItem.quantity, holdUpdate, handleUpdateLineItem]);

  const price = (
    <div className={classes.price}>
      <Text
        fw="bold"
        className={
          !lineItem.merchandise.availableForSale ? classes.unusedPrice : ""
        }
      >
        {formatPrice(
          lineItem.cost.totalAmount.currencyCode,
          lineItem.cost.totalAmount.amount
        )}
      </Text>
      {lineItem.cost.subtotalAmount.amount >
        lineItem.cost.totalAmount.amount && (
        <Text className={classes.unusedPrice} size="md">
          {formatPrice(
            lineItem.cost.subtotalAmount.currencyCode,
            lineItem.cost.subtotalAmount.amount
          )}
        </Text>
      )}
    </div>
  );

  const tags = !lineItem.merchandise.availableForSale ? (
    <Badge mt="xxs" color="red">
      Out of Stock
    </Badge>
  ) : lineItem.merchandise.currentlyNotInStock ? (
    <Badge mt="xxs" color="green">
      Pre-Order
    </Badge>
  ) : null;

  const discounts = lineItem.discountAllocations.map((discount, index) => (
    <div className={classes.productDiscount} key={index}>
      You save -
      {formatPrice(
        discount.discountedAmount.currencyCode,
        discount.discountedAmount.amount
      )}
    </div>
  ));

  const productDetails = (props) => (
    <Group
      justify="space-between"
      align="stretch"
      wrap="nowrap"
      flex="1"
      gap="xs"
      {...props}
    >
      <Stack gap={0}>
        <Text
          component={Link}
          to={productPage}
          onClick={close}
          className={classes.title}
        >
          {lineItem.merchandise.product.title}
        </Text>
        {lineItem.merchandise.title !== "Default Title" && (
          <Text c="dimmed" size="md">
            {lineItem.merchandise.title}
          </Text>
        )}
        {tags}
      </Stack>
      {price}
    </Group>
  );

  const getLoaderProps = () => {
    switch (status) {
      case "ERROR":
        return {
          children: (
            <ErrorIcon
              style={{ marginTop: 6 }}
              size="24px"
              color="var(--mantine-color-red-filled)"
            />
          ),
        };
      case "WARNING":
        return {
          children: (
            <WarningIcon
              style={{ marginTop: 6 }}
              size="24px"
              color="var(--mantine-color-yellow-filled)"
            />
          ),
        };
      case "SUCCESS":
        return {
          children: (
            <SuccessIcon
              style={{ marginTop: 6 }}
              size="24px"
              color="var(--mantine-color-teal-filled)"
            />
          ),
        };
      default:
        return null;
    }
  };

  return (
    <Stack className={classes.lineItem}>
      {productDetails({ hiddenFrom: "xs" })}
      <Group justify="space-between" align="stretch" wrap="nowrap">
        <Anchor
          component={Link}
          to={productPage}
          onClick={close}
          className={classes.imageWrapper}
        >
          <LoadingOverlay
            hiddenFrom="xxs"
            visible={loading}
            overlayProps={{ color: "var(--mantine-color-white)" }}
            loaderProps={getLoaderProps()}
          />
          <Image
            src={lineItem.merchandise?.image?.url}
            alt={lineItem.merchandise?.image?.altText || ""}
            className={classes.image}
            fallbackSrc={placeholder}
          />
        </Anchor>
        <Stack gap={0} flex="1" justify="flex-end">
          {productDetails({ visibleFrom: "xs" })}
          {discounts}
          <Group justify="flex-end" gap="xs" wrap="nowrap">
            <Loader
              size="sm"
              mt="xxs"
              mod={{ visible: loading }}
              className={classes.loader}
              visibleFrom="xxs"
              {...getLoaderProps()}
            />
            <ActionIcon
              disabled={loading}
              className={classes.removeButton}
              color="red"
              variant="outline"
              onClick={handleRemoveLineItem}
              aria-label={`Remove ${lineItem.merchandise.product.title} from cart`}
            >
              <RemoveIcon />
            </ActionIcon>
            {lineItem.merchandise.availableForSale && (
              <QuantityInput
                size="sm"
                quantity={quantity}
                onChange={(value) => {
                  setQuantity(value);
                  setDirty(true);
                }}
                onFocus={() => setHoldUpdate(true)}
                onBlur={() => setHoldUpdate(false)}
                max={99}
                aria-label="Quantity"
                disabled={loading}
              />
            )}
          </Group>
        </Stack>
      </Group>
    </Stack>
  );
}
